import React, { useState } from "react";
import "./SideButtons.css";
import Benefit from "../Modal/Benefits";
import Branches from "../Modal/Branches";

function SideButtons() {
  const [benefitPopup, setBenefitPopup] = useState(false);
  const [BranchesPopup, setBranchesPopup] = useState(false);
  return (
    <div className="Sidebutton_container">
      <div className="sideButtons">
        <button
          onClick={() => setBenefitPopup(true)}
          className="benefitSideButton"
        >
          Benefit
        </button>
        <button
          onClick={() => setBranchesPopup(true)}
          className="branchSideButton"
        >
          Branches
        </button>
      </div>
      {benefitPopup && (
        <Benefit setTrigger={(v) => setBenefitPopup(v)} trigger={true} />
      )}
      {BranchesPopup && (
        <Branches setTrigger={(v) => setBranchesPopup(v)} trigger={true} />
      )}
    </div>
  );
}

export default SideButtons;
