import React from "react";
import "./VideoPlayer.css";
import { ReactVideo } from "reactjs-media";
import comingSoon from "../assets/Carousel images & buttons/thumbnail.png";
import { Close } from "@material-ui/icons";
const VideoPlayer = ({ setPlayVideo }) => {
  return (
    <div className="videoPlayer">
      <div className="vidClose" onClick={() => setPlayVideo(false)}>
        <Close className="close" style={{ color: "#a31e22" }} />
      </div>
      <div className="player">
        <ReactVideo
          src=""
          poster={comingSoon}
          primaryColor="red"
          width={200}
          height={100}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
