import React from "react";
import "./Carousel.css";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from "../assets/Carousel images & buttons/img1.png";
import img2 from "../assets/Carousel images & buttons/img2.png";
import img3 from "../assets/Carousel images & buttons/img3.png";
import img4 from "../assets/Carousel images & buttons/img4.png";
import btn from "../assets/Carousel images & buttons/saveNow.png";
import investBtn from "../assets/Carousel images & buttons/investNow.png";
import sidebar from "../assets/Carousel images & buttons/sidebar.png";
import mini_img1 from "../assets/Carousel images & buttons/mini_img1.png";
import mini_img2 from "../assets/Carousel images & buttons/mini_img2.png";
import mini_img3 from "../assets/Carousel images & buttons/mini_img3.png";
import mini_img4 from "../assets/Carousel images & buttons/mini_img4.png";
import mini_invest from "../assets/Carousel images & buttons/mini_investnow.png";
import mini_save from "../assets/Carousel images & buttons/mini_savenow.png";
import mini_btn_img from "../assets/Carousel images & buttons/btn_img_3.png";

function CarouselComponent({ setTrigger, mover }) {
  return (
    <div className="carousel">
      <Carousel prevLabel="" nextLabel="">
        <Carousel.Item interval={5000}>
          <img className="img" src={img1} alt="First slide" />
          <img className="mini_img" src={mini_img1} alt="First slide" />
          <div className="btn">
            <img
              className="btn_image"
              src={btn}
              alt="First slide"
              onClick={() => setTrigger(true)}
            />
            <img
              className="mini_btn_image"
              src={mini_save}
              alt="First slide"
              onClick={mover}
            />
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img className="img" src={img2} alt="First slide" />
          <img className="mini_img" src={mini_img2} alt="First slide" />
          <div className="btn">
            <img
              className="btn_image"
              src={investBtn}
              alt="First slide"
              onClick={() => setTrigger(true)}
            />
            <img
              className="mini_btn_image"
              src={mini_invest}
              alt="First slide"
              onClick={mover}
            />
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img className="img" src={img3} alt="First slide" />
          <img className="mini_img" src={mini_img3} alt="First slide" />

          <Link to="/runningchits">
            <div className="btn">
              <img
                className="btn_image btnUP"
                src={investBtn}
                alt="First slide"
              />
              <img
                className="mini_btn_image"
                src={mini_invest}
                alt="First slide"
              />
            </div>
          </Link>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img className="img" src={img4} alt="First slide" />
          <img className="mini_img" src={mini_img4} alt="First slide" />

          <Link to="/runningchits">
            <div className="btn">
              <img
                className="btn_image btnUP"
                src={mini_btn_img}
                alt="First slide"
              />
              <img
                className="mini_btn_image"
                src={mini_btn_img}
                alt="First slide"
              />
            </div>
          </Link>
        </Carousel.Item>
      </Carousel>
      <div className="sidebar">
        <img src={sidebar} alt="" />
      </div>
    </div>
  );
}

export default CarouselComponent;
