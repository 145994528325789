import React, { useEffect } from "react";
import "./AboutUsMain.css";
import { Button } from "react-bootstrap";
import GetAppIcon from "@material-ui/icons/GetApp";
import logo2 from "../assets/footer/logo.png";
// import about from "../assets/AboutUsMain/about us.png";
import logo from "../assets/AboutUsMain/Syon chit logo@2x.png";
import bImg from "../assets/AboutUsMain/illustration.png";
import name from "../assets/AboutUsMain/name.svg";
import brochurePDF from "../assets/brochure.pdf";

function AboutUsMain() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="aboutUsMain">
      <div className="container">
        {/* <img src={about} alt="about" /> */}
        <h1>About Us</h1>
        <div className="tag">
          <img className="logo" src={logo} alt="logo" />
          <img className="logo2" src={logo2} alt="logo" />
          <img className="name" src={name} alt="name" />
        </div>

        <div className="information">
          <p>
            It is well known that at unfavorable times, Money can turn tides in
            one’s life, loans are inevitable in everyone’s life amidst crisis
            and purchases, investments and savings are the desires made by any
            common man, here we must specify to you that chits are both a
            savings-cum-loan scheme, this one of a kind product is offered to
            all classes of consumers, we maintain high class righteousness and
            are professionally laudable. Syon chits toils day and night to offer
            chit services to its customers with dynamic requirements.
          </p>
          <p>
            Deeply respectable and noble, we began our operations in the month
            of June 2020, and since then we have not turned back, our firm
            beliefs in ethics and professionalism ensures that we are completely
            of aid to our customer during tough times. Our experienced workforce
            and their homely nature completely guarantees a splendid investing
            experience, our personnel are well versed at what they do, their
            impeccable knowledge of financial markets and the ability to
            capitalize appearing chances make us second to none.
          </p>
          <p>
            We have successfully made our name for earning the customer’s trust
            through our unique operations. Our intricate networks and limpid
            accounting system has led to the advent of modern investing,
            gradually shifting the focus from orthodox banking.
          </p>
          <p>
            We look forward to become one of India’s largest financial service
            provider by relentlessly pursuing our goals
          </p>
        </div>
        <div className="botton">
          <a href={brochurePDF} download="brochure.pdf">
            <Button className="btn">
              Download Brochure
              <GetAppIcon />
            </Button>
          </a>
        </div>

        <div className="bottomIllustration">
          <img src={bImg} alt="ill" />
        </div>
      </div>
    </div>
  );
}
export default AboutUsMain;
