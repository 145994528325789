import React, { useEffect } from "react";
import "./RunningChit.css";
import ig from "../assets/Runningchit/image.svg";

function RunningChit() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const chit_25 = ["1,00,000", "2,00,000", "5,00,000", "25,00,000"];
  const chit_30 = ["1,50,000", "3,00,000", "15,00,000"];
  const chit_40 = ["2,00,000", "3,00,000", "5,00,000", "10,00,000"];
  const monthly_25 = ["4,000", "8000", "20,000", "1,00,000"];
  const monthly_30 = ["5,000", "10,000", "50,000"];
  const monthly_40 = ["5,000", "7,500", "12,500", "25,000"];
  const dividend_25 = ["1,000", "2000", "5,000", "25,000"];
  const dividend_30 = ["1,250", "2,500", "12,500"];
  const dividend_40 = ["1,250", "1,875", "3,125", "6,250"];
  const collection_25 = ["160", "320", "800", "4,000"];
  const collection_30 = ["200", "400", "2000"];
  const collection_40 = ["200", "300", "500", "1,000"];
  const prize_25 = ["70,000", "1,40,000", "3,50,000", "17,50,000"];
  const prize_30 = ["1,05,000", "2,10,000", "10,50,000"];
  const prize_40 = ["1,40,000", "2,10,000", "3,50,000", "7,00,000"];
  // const [buttonPopup, setbuttonPopup] = useState(false);
  return (
    <div className="runningChit">
      <div className="runchit_content">
        <div className="runchit_header">
          <h2>Our Currently Running Chit Groups</h2>
        </div>
        <div className="runchit_head">
          <ul>
            <li>Chit Value</li>
            <li>Monthly Installment</li>
            <li>Dividend</li>
            <li>Daily Collection</li>
            <li>Prize Amount</li>
          </ul>
        </div>
        <div className="runchit_period">
          <div className="month">
            <span>25 Months</span>
          </div>
          <div className="box">
            <ul>
              <li>
                <div className="inside-box">
                  {chit_25.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_25.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {monthly_25.map((month) => (
                    <>
                      {" "}
                      <span>{month}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_25.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {dividend_25.map((divide) => (
                    <>
                      {" "}
                      <span>{divide}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_25.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {collection_25.map((collect) => (
                    <>
                      {" "}
                      <span>{collect}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_25.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {prize_25.map((prize) => (
                    <>
                      {" "}
                      <span>{prize}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="runchit_period">
          <div className="month">
            <span>30 Months</span>
          </div>
          <div className="box">
            <ul>
              <li>
                <div className="inside-box">
                  {chit_30.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_30.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {monthly_30.map((month) => (
                    <>
                      {" "}
                      <span>{month}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_30.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {dividend_30.map((divide) => (
                    <>
                      {" "}
                      <span>{divide}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_30.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {collection_30.map((collect) => (
                    <>
                      {" "}
                      <span>{collect}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_30.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {prize_30.map((prize) => (
                    <>
                      {" "}
                      <span>{prize}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="runchit_period">
          <div className="month">
            <span>40 Months</span>
          </div>
          <div className="box">
            <ul>
              <li>
                <div className="inside-box">
                  {chit_40.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_40.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {monthly_40.map((month) => (
                    <>
                      {" "}
                      <span>{month}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_40.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {dividend_40.map((divide) => (
                    <>
                      {" "}
                      <span>{divide}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_40.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {collection_40.map((collect) => (
                    <>
                      {" "}
                      <span>{collect}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="line">
                  {chit_40.map((chit) => (
                    <>
                      {" "}
                      <span>{chit}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <div className="inside-box">
                  {prize_40.map((prize) => (
                    <>
                      {" "}
                      <span>{prize}</span>
                      <br></br>
                    </>
                  ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <img src={ig} alt="ig" />
      </div>
    </div>
  );
}

export default RunningChit;
