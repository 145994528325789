import React from "react";
import "./Branches.css";
import Close from "@material-ui/icons/Close";
import Blogo from "../assets/footer/logo temp.svg";

function Branches(props) {
  return props.trigger ? (
    <div className="Branches_container" onClick={() => props.setTrigger(false)}>
      <div className="Branches_info">
        <Close
          className="Branchclose"
          onClick={() => props.setTrigger(false)}
          fontSize="large"
        />
        <button
          className="Branches_back"
          onClick={() => props.setTrigger(false)}
        >
          Go Back
        </button>

        {props.children}
        <h1> Branches</h1>
        <div className="branch_content">
          <div className="Reg_office">
            <img className="office_logo" src={Blogo} alt="Blogo" />
            <h2>
              {" "}
              <a
                rel="noreferrer"
                href="https://maps.app.goo.gl/hVp6ha892ir8g5tA8"
                target="_blank"
              >
                {" "}
                Registered office
              </a>
            </h2>
            <address>
              #27, 27th cross, Jayanagar 6th block, Bengaluru, Karnataka - 560
              070
              <p>
                Contact: <a href="tel:+91 87620 76229">+91 87620 76229</a>
              </p>
              <p>
                Email:{" "}
                <a href="mailto:contact@syonchits.in">contact@syonchits.in</a>
              </p>
            </address>
          </div>

          <div className="Reg_office">
            <img className="office_logo" src={Blogo} alt="Blogo" />
            <h2>Mysuru Branch</h2>
            <div className="Reg_office_details">
              <address>
                #267/1-A, 2nd Floor Ram Villas Road, {/* D-1/1-A, */}
                <p>Devaraj Moholla, Mysuru - 570001</p>
                <p>
                  Contact: <a href="tel:+91 82135 00063">+91 82135 00063</a>{" "}
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:contact.Mys@syonchits.in">
                    contact.Mys@syonchits.in
                  </a>
                </p>
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
export default Branches;
