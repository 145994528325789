// import React, { useState } from "react";
import "./Curious.css";
import pig from "../assets/Carousel images & buttons/pig.svg";
import playButton from "../assets/Carousel images & buttons/Play button vector.png";
import questionMark from "../assets/Carousel images & buttons/Question mark vector.png";
import { Link } from "react-router-dom";
// import VideoPlayer from "./VideoPlayer";
// import comingSoon from "../assets/Carousel images & buttons/thumbnail.png";

function Curious({ setPlayVideo }) {
  // const [playVideo, setPlayVideo] = useState(false);

  return (
    <div className="curious">
      <div className="curious__top">
        <div className="curious__left">
          <h1>Curious </h1>
          <h1>on how a </h1>
          <h1>Chit fund works ?</h1>
          <p>Tap on the video to know how !!!</p>
          <Link to="/chitwork">
            <button>Read more</button>
          </Link>
        </div>
        <div className="curious__right">
          <div
            className="curious__rightContainer"
            onClick={() => setPlayVideo(true)}
          >
            <div className="curious__innerContainer">
              <img id="playButton" src={playButton} alt="play" />
            </div>
            <img id="questionMark" src={questionMark} alt="" />
          </div>
          <Link to="/chitwork">
            <button className="mobile">Read more</button>
          </Link>
        </div>
      </div>
      <div className="curious__bottom">
        {/* {playVideo && <VideoPlayer />} */}
        <div className="curious__container">
          <h1>Dive in to know our Running chit groups</h1>
          <Link to="/runningchits">
            <button>Our Running Chit Groups</button>
          </Link>
          <img src={pig} alt="svg" />
        </div>
      </div>
    </div>
  );
}

export default Curious;
