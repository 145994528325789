import React, { useEffect } from "react";
import "./ChitWork.css";
import top1 from "../assets/Chitworks/top1.png";
import arrow from "../assets/Chitworks/arrow.svg";
import mini_top from "../assets/Chitworks/mini_top.png";

function ChitWork() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="chitWork">
      <div className="chitwork_content">
        <div className="top_image">
          <img className="inner_top" src={top1} alt="top1" />
          <img
            className="mobile_inner_top"
            src={mini_top}
            alt="How Chit Works"
          />
        </div>
        <div className="scroll_over">
          <div className="chitwork_arrow">
            <img className="arrow_image" src={arrow} alt="arrow" />
          </div>
          <div className="chitwork_info_outter">
            <div className="chitwork_info">
              <p>
                In a chit fund system, a group of people regularly add to the
                valuation of the chit for a time equal to the number of
                investors (members or subscribers). The sum received shall be
                requested by the individual chosen by an auction or a lucky
                draw.
              </p>
              <p>
                The one who offers the lowest bid (will agree to claim the
                lowest amount) gets the money in the auction allocation scheme.
                This is regarded as a reverse auction scheme. After deducting
                the commission of the contractor and other costs, the amount
                forgiven by the winning offeror would be allocated to the other
                participants equally. It is called a dividend for each
                shareholder. The winning bidder must continue to invest even
                after claiming the sum.
              </p>
              <p>
                The chit fund starts for the months equal to the number of
                investors at the pre-decided date. The customers add their
                monthly payments to the pot. There is then an open auction to
                enable participants to pay for the worth of the chit fund. The
                subscriber who is ready to receive the smallest amount is
                informed of the winner and will take the money for that month.
              </p>
            </div>
          </div>
        </div>

        <div className="chitwork_steps">
          <div className="steps_head">
            <h2>The Math Behind it</h2>
            <div className="textArea">
              <p>
                Suppose there are 25 participants of the chit program which is
                worth 1,00,000 for a period of 25 months, each with a monthly
                installment of Rs. 4,000, which is for a 1-month pool of
                1,00,000 . All members of the functioning chit group shall pay a
                collective amount of 1,00,000 for the first month which shall be
                considered as an FD which is compulsory under the registrar of
                chit funds. In the second month, the member of the chit group
                who shall be selected through a lucky draw shall receive a
                maximum 70% of the chit amount ie. 70,000 until the end of the
                sealing period which might be the end of 8th month. Of the
                remaining 30% (30,000), 5% (5000) is foreman commission and 25%
                (25,000) is the dividend which is divided by 25, resulting in a
                monthly dividend of 1000, which is deducted from the instalment
                of 4,000 resulting in a payment of Rs. 3000 only from the third
                month to the end of the sealing period. Once the sealing period
                is closed and open auction comes into the picture, the
                installment might vary depending upon the bid, for example, in
                the open bid if there is a bid of 20,000, 5,000 Rs shall end up
                as foreman commission and the other 15,000 shall be divided by
                25, resulting in 600 Rs, which is deducted by the monthly
                installment resulting in a payment of 3400 for that month.
              </p>
              <p>
                PLEASE NOTE: In the chit group of 25 months, sealing period will
                be of maximum 8 months, similarly in the group of 40 months, it
                is 12 months and in the group of 30 months, it is 10 months.
              </p>

              <p>
                YOU CAN ALSO EARN 0% INTEREST FREE LOAN, To know more contact
                us!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChitWork;

/*
<div className="steps_outter">
              <div className="steps_outter_box">
                <div className="step_1_2">
                  <div className="circle">
                    <span>1</span>
                  </div>
                  <div className="rectangle">
                    <p>
                      Suppose there are 50 participants of the chit fund
                      program, each with a monthly installment of approximately
                      1,000, which is for a 1-month pool of approximately 50
                      000.
                    </p>
                  </div>
                </div>
                <div className="step_1_2">
                  <div className="circle">
                    <span>2</span>
                  </div>
                  <div className="rectangle">
                    <p>
                      The member who offers to bring home the lowest sum of the
                      chit fund receives the bid when the auction is revealed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="steps_outter_box_next">
                <div className="step_1_2">
                  <div className="circle">
                    <span>3</span>
                  </div>
                  <div className="rectangle">
                    <p>
                      Suppose the winning bidder agrees to consider the value of
                      the chit fund in 45,000 rupees a month.
                    </p>
                  </div>
                </div>
                <div className="step_1_2">
                  <div className="circle">
                    <span>4</span>
                  </div>
                  <div className="rectangle">
                    <p>
                      After the fees of the organizer are subtracted, the
                      remaining balance – Rs. 5,000 – is evenly divided among
                      the other 49 candidates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          
*/
