import React from "react";
import "./WhyUs.css";
// import WhyUsCarousel from "./WhyUsCarousel";
import WhyUsCarousel2 from "./WhyUsCarousel2";
import card1 from "../assets/Carousel images & buttons/card 1.png";
import card2 from "../assets/Carousel images & buttons/card 2.png";
import card3 from "../assets/Carousel images & buttons/card 3.png";
import card4 from "../assets/Carousel images & buttons/card 4.png";
import card5 from "../assets/Carousel images & buttons/card 5.png";
function WhyUs() {
  // const [fCar, setFCar] = useState(true);
  const images = [
    {
      id: 1,
      src: card1,
      alt: "",
    },
    {
      id: 2,
      src: card2,
      alt: "",
    },
    {
      id: 3,
      src: card3,
      alt: "",
    },
    {
      id: 4,
      src: card4,
      alt: "",
    },
    {
      id: 5,
      src: card5,
      alt: "",
    },
  ];
  return (
    <div className="whyus">
      <div className="leftBox"></div>
      <div className="textField">
        <div className="whyus__image">
          <h1>Why us?</h1>
        </div>
        <div className="whyus__texts">
          <p>
            The immense experience and market expertise that we bring to the
            arena are to be found inimitable, our congenial behavior towards you
            shall surely leave you spell bound, pay a visit to your nearest
            branch to have a seamless experience.
          </p>
        </div>
      </div>
      <div className="whyus__carousel">
        <WhyUsCarousel2 images={images} />
      </div>
      <div className="rightBoxContainer">
        <div className="rightBox"></div>
      </div>
    </div>
  );
}

export default WhyUs;
