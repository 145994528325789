import React, { useEffect } from "react";
import "./Home.css";
import About from "../HomeComponents/About";
import Carousel from "../HomeComponents/Carousel";
import WhyUs from "../HomeComponents/WhyUs";
import Curious from "../HomeComponents/Curious";

export default function Home({ setTrigger, mover, setPlayVideo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <Carousel setTrigger={setTrigger} mover={mover} />
      <About />
      <WhyUs />
      <Curious setPlayVideo={setPlayVideo} />
    </div>
  );
}
