import React from "react";
import "./ContactUs.css";
import Close from "@material-ui/icons/Close";
import logo from "../assets/footer/logo.png";
import name from "../assets/header/logo_name.svg";
import image from "../assets/Popup/illustration.svg";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PinterestIcon from "@material-ui/icons/Pinterest";

function ContactUs(props) {
  return props.trigger ? (
    <div className="contactus">
      <div className="contact_content">
        <Close
          className="close"
          style={{ color: "#a31e22" }}
          onClick={() => props.setTrigger(false)}
        />
        {props.children}

        <div className="contact_logo">
          <img className="logo" src={logo} alt="logo" />
          <img className="logo_name" src={name} alt="name" />
          <span>Contact : 080 41733720 | + 91 87620 76229</span>
          <span>E-mail : contact@syonchits.in</span>
          <img className="logo_image" src={image} alt="logo" />
        </div>
        <div className="contact_details">
          <div className="head">
            <span style={{ color: "#a31e22" }}>Contact us</span>
          </div>
          <div className="input_field">
            <form
              action="https://formsubmit.co/contact@syonchits.in"
              method="POST"
            >
              <input
                type="hidden"
                name="_next"
                value="https://syonchits.in"
              ></input>
              <input type="hidden" name="_captcha" value="false" />
              <input
                name="name"
                className="input_name"
                type="text"
                placeholder="Name"
                required
              />
              <input
                name="phone"
                className="input_phone"
                type="text"
                placeholder="Phone no"
                required
              />
              <input
                name="email"
                className="input_email"
                type="text"
                placeholder="Email-id"
                required
              />
              <button type="submit" className="submit">
                Submit
              </button>
              <div className="contact_social_media">
                <a
                  href="https://www.facebook.com/pages/category/Hedge-Fund/Syon-Chit-Fund-Pvt-Ltd-101284218367368/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon fontSize="large" />
                </a>
                <a
                  href="https://www.instagram.com/syon_chit_funds/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon fontSize="large" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=918762076229"
                  target="_blank"
                  rel="noreferrer"
                >
                  <WhatsAppIcon fontSize="large" />
                </a>
                <a
                  href="https://in.pinterest.com/Syonchitfunds"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PinterestIcon fontSize="large" />
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default ContactUs;
