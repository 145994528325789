import React from "react";
import "./DownloadButton.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import brochurePDF from "../assets/brochure.pdf";
const DownloadButton = () => {
  return (
    <div className="downloadButton">
      <a href={brochurePDF} download="brochure.pdf">
        <button>
          <GetAppIcon /> Download Brouchure
        </button>
      </a>
    </div>
  );
};

export default DownloadButton;
