import React from "react";
import "./Benefits.css";
import Close from "@material-ui/icons/Close";

function Benefits(props) {
  return props.trigger ? (
    <div className="Benefits_container" onClick={() => props.setTrigger(false)}>
      <div className="Benefits_content">
        <Close className="Bclose" onClick={() => props.setTrigger(false)} />
        <button
          className="Benefits_back"
          onClick={() => props.setTrigger(false)}
        >
          Go Back
        </button>
        {props.children}
        <h1>Benefits (P.A)</h1>

        <div className="Benefits_investment">
          <h2>When considered as investment</h2>
          <div className="invest">
            <ul className="investment_fl">
              <li>40 months</li>
              <li>25 months</li>
              <li>30 months</li>
            </ul>

            <ul className="investment_sl">
              <li>9% - 12%</li>
              <li>7% - 10%</li>
              <li>8% - 11%</li>
            </ul>
          </div>
        </div>

        <div className="Benefits_investment">
          <h2> When considered as loan</h2>
          <div className="loan">
            <ul className="investment_fl">
              <li>40 months</li>
              <li>25 months</li>
              <li>30 months</li>
            </ul>

            <ul className="investment_sl">
              <li>4% - 9%</li>
              <li>6% - 12%</li>
              <li>5% - 10%</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default Benefits;
