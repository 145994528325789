import React from "react";
import "./MinFooter.css";
import logo from "../assets/header/logo_name.svg";
import image from "../assets/Popup/illustration.svg";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PinterestIcon from "@material-ui/icons/Pinterest";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
export default function MinFooter() {
  return (
    <div className="minFooter">
      <div className="minFooterTitle">
        <h1>Interested To Join</h1>
        <h1>Syon Chit Fund</h1>
      </div>
      <div className="minFooter__form">
        <div className="minFooter__formTitle">
          <span>Contact us</span>
        </div>
        <div className="minFooter__inputs">
          <form
            action="https://formsubmit.co/contact@syonchits.in"
            method="POST"
          >
            <input type="hidden" name="_subject" value="Contacted Us"></input>
            <input
              type="hidden"
              name="_next"
              value="https://syonchits.in"
            ></input>
            <input type="hidden" name="_captcha" value="false" />
            <input name="name" type="text" placeholder="Name" required />
            <input
              name="phone"
              type="number"
              placeholder="Phone no."
              required
            />
            <input name="email" type="email" placeholder="Email-id" required />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className="minFooter__address">
        <img src={logo} alt="logo" />
        <div className="minFooter__address">
          <p>
            Contact:{" "}
            <a className="remHighlights" href="tel:080 41733720">
              080 41733720
            </a>{" "}
            |{" "}
            <a
              className="remHighlights"
              href="tel:+ 91 87620
            76229"
            >
              + 91 87620 76229
            </a>
          </p>
          <p>
            E-mail :{" "}
            <a className="remHighlights" href="mailto:contact@syonchits.in">
              contact@syonchits.in
            </a>
          </p>
        </div>
        <div className="minFooter__illustration">
          <img src={image} alt="ill" />
        </div>
      </div>
      <div className="extraDiv">
        <a
          href="https://www.facebook.com/pages/category/Hedge-Fund/Syon-Chit-Fund-Pvt-Ltd-101284218367368/"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon className="fb" />
        </a>
        <a
          href="https://www.instagram.com/syon_chit_funds/"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon className="insta" />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=918762076229"
          target="_blank"
          rel="noreferrer"
        >
          <WhatsAppIcon className="whatsapp" />
        </a>
        <a
          href="https://in.pinterest.com/Syonchitfunds"
          target="_blank"
          rel="noreferrer"
        >
          <PinterestIcon className="pinterest" />
        </a>
      </div>
    </div>
  );
}
