import React, { useState } from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";
import logo_center from "../assets/header/logo_center.svg";
import logo_name from "../assets/header/logo_name.svg";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Contact from "../Popup/ContactUs";
function Header() {
  const [ContactPopup, setContactPopup] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <div className="header">
      <div className="header__main">
        <div className="header__up">
          <NavLink
            exact
            to="/"
            activeClassName="selected"
            onClick={handleClick}
          >
            <img src={logo_name} alt="name" className="name" />
          </NavLink>

          <img src={logo_center} alt="logo" className="logo_center" />
          <div className="header__grp">
            <button className="join" onClick={() => setContactPopup(true)}>
              Join now
            </button>
            <Contact
              trigger={ContactPopup}
              setTrigger={setContactPopup}
            ></Contact>
          </div>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <CloseIcon /> : <MenuIcon />}
          </div>
        </div>

        <div className="header__down">
          <ul className={click ? "header__nav active" : "header__nav"}>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="selected"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/aboutus"
                activeClassName="selected"
                onClick={handleClick}
              >
                About us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/chitwork"
                activeClassName="selected"
                onClick={handleClick}
              >
                How chit works
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/runningchits"
                activeClassName="selected"
                onClick={handleClick}
              >
                Our running chit groups
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/FAQ"
                activeClassName="selected"
                onClick={handleClick}
              >
                FAQ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="header__minimized">
        <div className="headerMain__minimized">
          <NavLink
            exact
            to="/"
            activeClassName="selected"
            onClick={handleClick}
          >
            <img src={logo_name} alt="name" className="name__minimized" />
          </NavLink>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <CloseIcon /> : <MenuIcon />}
          </div>
        </div>
  </div> */}
    </div>
  );
}

export default Header;
