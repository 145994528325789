import React, { useEffect } from "react";
import "./FAQ.css";

function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ques = [
    {
      q: "How safe is my money with Syon chit funds?",
      ans: "The security of one's assets in someone else's possession depends on the fairness and dignity of the boss. The history of work of Syon Chit Funds shows that they have not paid anybody's money in default. Interest on advance in both situations was charged in erroneous cases of overdue payments.",
    },
    {
      q: "How is chit fund a better saving option?",
      ans: "When an individual joins a chit fund, he or she has to save a small portion of his/her income to cover his/her expected savings or expenses. In case of an emergency, it is much better, easier, quicker and cheaper to borrow from the Chit Fund. If an individual wishes to save up to the end, a subscriber receives a much higher return by means of the chit dividends.",
    },
    {
      q: "Who is a prized subscriber?",
      ans: "The coveted subscriber is a subscriber who has raised his chit or is paying the sum.",
    },
    {
      q: "What are the benefits?",
      ans: `Persons wishing to save money in the second half of the chit era and receiving the coveted sum would expect a significantly higher return (12-18 percent per annum) than their regular investments on a deposit. 
      ${" "}
      Many that need money will collect money because they need money by getting the price/offer number. In such cases, the interest or chit loss is even lower than in the market. 
      ${" "}
      All in the Chit Community needs to save a small amount of its profits to fund their dreams such as the purchase of a lot, the construction of a building, marriage costs, the purchase of house articles or the discharge of an established responsibility, etc.`,
    },
    {
      q: "What is an auction?",
      ans: "Auction is a process to identify a non-prized subscriber who would like to take a chit at the highest allowable discount. An auction is opened, within the five minutes permitted by each auction to all non-prized subscribers who have paid up to date their increments for bidding for the highest auction reduction.",
    },
    {
      q: "How many sureties are required?",
      ans: "A minimum of three safeties or guarantors are normally agreed on for all chits other than members of the household. Any such guarantees or guarantors' basic salary or taxable earnings shall not be less than 20% of the future liabilities of the chits.",
    },
    {
      q: "What are the benefits to the company?",
      ans: "The chit fund firm that manages the chit industry known as Foreman, charges a fee or service charge of 5% of the chit value of its performed services. The foreman must assemble a group of people and set up a bunch, collect money from each subscriber each month, arrange to pay precious money to one person per month and raise money from the precious subscribers, etc.",
    },
    {
      q: "What regulations does Syon follow?",
      ans: "Under the chit fund Act 1982, the government agency regulates / oversees all of the company's operation.",
    },
    {
      q: "What action will be taken against defaulters?",
      ans: "Penalty is imposed on all customers who pay annual installments in arrears. In the event of a non-priced abonnement default over an on-going term of three months, the non-priced abonor is excluded. After the expiration of the specified term the real sum that he pays in the chit minus business commission is repaid to the defaulting subscriber. If a cherished subscriber defaults for a cumulative duration of 3 months, it receives a levy of 6 percent per month and the precious subscriber does not receive a dividend. Legal proceedings will be instituted for recovery of all dues against the aforementioned precious defaulting customer and his guarantors in statute.",
    },
    {
      q: "Can a subscriber withdraw?",
      ans: "The foreman has to locate an alternative subscriber in his place when a subscriber cancels. The chit fund corporation would have to arrange for a prized subscriber per month to pay funds on behalf of such a defaulting subscriber. The withdrawal is then allowed but at the completion of the chit term the real money paid to the defaulting customer by the subscriber would be reimbursed at the company's chit minus commission.",
    },
    {
      q: "What are the IT benefits?",
      ans: "The depletion of chit may be deducted from professional or company revenues. The net surplus or dividends must be given as income after completing the chit period; however, some courts have held that the revenues are not taxable.",
    },
    {
      q: "What are the modes of payment?",
      ans: "Cash, cheques, demand draft, pay order, bank transfer and online transfers through an UPI QR code are accepted modes of payment.",
    },
  ];
  return (
    <div className="faq">
      <div className="content">
        <div className="heading">
          <h1>Frequently Asked Question?</h1>
        </div>

        {ques.map((qu) => (
          <>
            <div className="question">{qu.q}</div>
            <div className="ans">{qu.ans}</div>
          </>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
