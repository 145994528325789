import React, { useState } from "react";
import "./Footer.css";
import logo from "../assets/footer/logo.png";
import swan from "../assets/footer/swan.svg";
import Benefit from "../Modal/Benefits";
import Branches from "../Modal/Branches";
import Contact from "../Popup/ContactUs";
import { Link } from "react-router-dom";

function Footer() {
  const [BenefitPopup, setBenefitPopup] = useState(false);
  const [BranchesPopup, setBranchesPopup] = useState(false);
  const [ContactPopup, setContactPopup] = useState(false);
  return (
    <div className="footer">
      <div className="footer__nav">
        <div className="f__nav">
          <Link to="/FAQ">
            <a href="#!">FAQ</a>
          </Link>
          |
          <a id="contactA" onClick={() => setContactPopup(true)}>
            Contact us
          </a>{" "}
          |
          <Contact
            trigger={ContactPopup}
            setTrigger={setContactPopup}
          ></Contact>
          <a href="#!" className="inactive">
            Career
          </a>{" "}
          |
          <a
            href="#!"
            className="Benefit"
            onClick={() => setBenefitPopup(true)}
          >
            Benefits
          </a>{" "}
          <Benefit
            trigger={BenefitPopup}
            setTrigger={setBenefitPopup}
          ></Benefit>
          |
          <a href="#!" className="inactive">
            Gallery
          </a>
        </div>
      </div>
      <div className="footer__info">
        <div className="address__tab">
          <div className="address__logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="address">
            <h3>
              {" "}
              <a
                className="remHighlight"
                rel="noreferrer"
                href="https://maps.app.goo.gl/hVp6ha892ir8g5tA8"
                target="_blank"
              >
                Syon Chit fund Pvt Ltd
              </a>{" "}
            </h3>
            <span>#27,27th cross, Jayanagra 6th block,</span>
            <span>Bengaluru - 560070</span>
            <a
              className="Branches"
              id="branchID"
              onClick={() => setBranchesPopup(true)}
            >
              Branches
            </a>
            <Branches
              trigger={BranchesPopup}
              setTrigger={setBranchesPopup}
            ></Branches>
            <span>
              Contact:{" "}
              <a className="remHighlight" href="tel:0804173320">
                0804173320
              </a>
            </span>
            <span>
              {" "}
              <a className="remHighlight" href="tel:+91 87620 76229">
                +91 87620 76229
              </a>{" "}
            </span>
            <span>
              E-mail:{" "}
              <a className="remHighlight" href="mailto:contact@syonchits.in">
                contact@syonchits.in
              </a>{" "}
            </span>
          </div>
        </div>
        <div className="newsletter">
          <span>Subscribe to our Newsletter</span>
          <form
            action="https://formsubmit.co/contact@syonchits.in"
            method="POST"
          >
            <input
              type="hidden"
              name="_subject"
              value="Newsletter Subscription"
            />
            <input
              type="hidden"
              name="_next"
              value="https://syonchits.in"
            ></input>
            <input type="hidden" name="_captcha" value="false" />
            <input type="email" placeholder="E-mail" required />
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="swan">
          <img src={swan} alt="swan" />
        </div>
      </div>
      <div className="border"></div>
    </div>
  );
}

export default Footer;
