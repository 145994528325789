import "./App.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import FAQ from "./FAQ/FAQ";
import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AboutUsMain from "./AboutUsMain/AboutUsMain";
import RunningChit from "./RunningChit/RunningChit";
import ChitWork from "./ChitWork/ChitWork";
import DownloadButton from "./MinComponents/DownloadButton";
import SideButtons from "./MinComponents/SideButtons";
import MinFooter from "./MinComponents/MinFooter";
import Contact from "./Popup/ContactUs";
import "./Popup/ContactUs.css";
import VideoPlayer from "./HomeComponents/VideoPlayer";

function App() {
  const [playVideo, setPlayVideo] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const mover = () => {
    window.scroll({ top: 10000, behavior: "smooth" });
  };
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route path="/FAQ">
            <FAQ />
          </Route>
          <Route path="/aboutus">
            <AboutUsMain />
          </Route>
          <Route path="/runningchits">
            <RunningChit />
          </Route>
          <Route path="/chitwork">
            <ChitWork />
          </Route>
          <Route path="/">
            <Home
              setTrigger={setTrigger}
              mover={mover}
              setPlayVideo={setPlayVideo}
            />
            {trigger && <Contact trigger={trigger} setTrigger={setTrigger} />}
            {playVideo && <VideoPlayer setPlayVideo={setPlayVideo} />}
          </Route>
        </Switch>
        <DownloadButton />
        <SideButtons />
        <Footer />
        <MinFooter />
      </div>
    </Router>
  );
}

export default App;
