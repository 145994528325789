import React from "react";
import "./About.css";
import bank from "../assets/Carousel images & buttons/bank.svg";
import about from "../assets/Carousel images & buttons/aboutUsHeading.png";
import dwnld from "../assets/Carousel images & buttons/downloadBrochure.png";
import brochurePDF from "../assets/brochure.pdf";
import { Link } from "react-router-dom";
function About() {
  return (
    <div className="about">
      <div className="whiteBox">
        <div className="info">
          <div className="image_bank">
            <img src={bank} alt="bank" />
          </div>
          <div className="know">
            <img src={about} alt="about" />
            <img src={bank} alt="bank" id="bankHidden" />
            <span>
              It is well known that at unfavorable times, Money can turn tides
              in one’s life, loans are inevitable in everyone’s life amidst
              crisis and purchases, investments and savings are the desires made
              by any common man, here we must specify to you that chits are both
              a savings-cum-loan scheme, this one of a kind product is offered
              to all classes of consumers, we maintain high class righteousness
              and are professionally laudable.
            </span>
            <Link to="/aboutus">
              <a href="#!">Know more..</a>
            </Link>
          </div>
        </div>
        <div className="button">
          <a href={brochurePDF} download="brochure.pdf">
            <img src={dwnld} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
